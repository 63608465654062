



















































// import OutsideContainer from '@/components/Containers/OutsideContainer.vue'
import LoginForm from '@/components/Auth/LoginForm.vue'
import Display from '@/utilities/Display'
import Assets from '@/definitions/assets'
import Labels from '@/definitions/labels'

export default {
  name: 'Login',
  components: {
    LoginForm,
  },
  computed: {
    mobile(): boolean { return Display.mobile(this.$vuetify) },
    logoWhite(): string { return Assets.logoWhite },
    bannerPic(): string { return Assets.bannerPic },
    appName(): string { return Labels.appName },
  },
}
