















































// eslint-disable-next-line import/extensions
import { AuthResponse } from '@/interfaces/main.d.ts'
import Urls from '@/utilities/Url'
import Errors from '@/utilities/Errors'

export default {
  name: 'LoginForm',
  data() {
    return {
      email: null,
      password: null,
      processing: false,
      errorMessage: '',
    }
  },
  methods: {
    /**
     * Handle submit button click
     */
    onSubmit(): void {
      this.fetchAuth()
        .then(() => {
          Errors.resetError()
          this.$router.push('/home')
        })
        .catch((err: string) => {
          this.errorMessage = err
        })
    },
    /**
     * Call auth api, set localStorage with token values
     */
    fetchAuth(): Promise<string> {
      return new Promise((resolve, reject) => {
        this.processing = true
        const body: string = JSON.stringify({
          username: this.email,
          password: this.password,
        })
        fetch(Urls.url('auth'), {
          method: 'POST',
          body,
        }).then((response: Response) => response.json())
          .then((data: AuthResponse) => {
            this.processing = false
            if (data.status !== 'Success') {
              reject(data.message)
            } else {
              localStorage.tk = data.identityToken
              localStorage.rt = data.refreshToken
              resolve('Success')
            }
          })
          .catch((err: string) => {
            reject(err)
          })
      })
    },
  },
}
